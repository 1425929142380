<template>
  <div ref="overlapModalRef" class="overlap-container">
    <a-modal :getContainer="() => $refs.overlapModalRef" width="65vw"  :visible="visible" @cancel="handleCancel"
      @update:visible="(val) => $emit('update:visible', val)" title="重叠8小时校验" :z-index="10001" centered>
      <div :class="`status-icon ${data.status}`">
        <div>
          <span>●</span>
          <span>{{ statusTip }}</span>
        </div>
        <div>
          <a-button type="primary" ghost @click="checkEightTime">查看示意图</a-button>
        </div>
      </div>

      <div v-if="overlapCommandList.length > 0" class="mt12">
        <CardTitle>
          <span v-if="data.status !== 'prohibit'">重叠事件列表</span>
          <span v-else>超8小时重叠事件列表</span>
        </CardTitle>
        <a-table class="mt12" rowKey="startDate" :data-source="overlapCommandList" :columns="overlapEventsColumns"
          :pagination="false" :scroll="{
            y: 800,
            x: 1450,
            scrollToFirstRowOnChange: true,
          }" :expandIconColumnIndex="-1" :expandIconAsCell="false" :expandedRowKeys="curExpandedRowKeys">
          <template #overlaptime="{ record }">
            {{ record.startDate + '~' + record.endDate }}
          </template>
          <template #RowRender="{ record }">
            <span @click="segmentedEditingClick(record.startDate)" style="cursor: pointer;font-size: 0.7vw;">
              <span v-if="curExpandedRowKeys.includes(record.startDate)">
                <DownOutlined />
              </span>
              <span v-else>
                <RightOutlined />
              </span>
            </span>
          </template>
          <template #expandedRowRender="{ record }">
            <div>
              <div v-if="record.assignmentOverlap.length > 0">
                <a-table :columns="currentAssignmentColumns" :data-source="record.assignmentOverlap" :pagination="false"
                  class="staffClass">
                  <template #status>
                    <span style="color: #3182CE">●</span>
                  </template>
                  <template #extensionAssumptionDate="{ text }">
                    <span>{{ text? text : '-' }}</span>
                  </template>
                  <template #certainty="{ text }">
                    <span>{{ text? text : '-' }}</span>
                  </template>
                </a-table>
              </div>
              <div v-if="record.workTask.length > 0">
                <a-table :columns="currentTaskColumns" :data-source="record.workTask" :pagination="false"
                  class="staffClass">
                  <template #status>
                    <span style="color: #3182CE">●</span>
                  </template>
                </a-table>
              </div>
            </div>
            <div v-if="record.assignmentOverlapList.length > 0">
              <a-table :columns="overlapAssignmentColumns" :data-source="record.assignmentOverlapList" :pagination="false"
                class="staffClass">
                <template #status>
                  <span style="color: #77CAED">●</span>
                </template>
                <template #extensionAssumptionDate="{ text }">
                  <span>{{ text? text : '-' }}</span>
                </template>
                <template #certainty="{ text }">
                  <span>{{ text? text : '-' }}</span>
                </template>
              </a-table>
            </div>
            <div v-if="record.workTaskList.length > 0">
              <a-table :columns="overlapTaskColumns" :data-source="record.workTaskList" :pagination="false"
                class="staffClass">
                <template #status>
                  <span style="color: #BCB1FF">●</span>
                </template>
              </a-table>
            </div>
          </template>
        </a-table>
      </div>
      <div v-if="data.assignmentList.length>0" class="mt12">
        <CardTitle>Assignment</CardTitle>
        <a-table class="mt12" rowKey="id" :data-source="assignmentList" :columns="assignmentColumns" :pagination="false"
          :scroll="{
            y: 800,
            x: 1300,
            scrollToFirstRowOnChange: true,
          }" :expandIconColumnIndex="-1" :expandIconAsCell="false" :expandedRowKeys="curExpandedAssignment">
          <template #type>
            <span>主</span>
          </template>
          <template #segmentationEndDate="{ record }">
            {{ record.actual_release_date }}
          </template>
          <template #RowRender="{ record }">
            <div v-if="record.staffAssignmentParts.length > 0">
              <span @click="assignmentSegmentedClick(record.id)" style="cursor: pointer;font-size: 0.7vw;">
                <span v-if="curExpandedAssignment.includes(record.id)">
                  <DownOutlined />
                </span>
                <span v-else>
                  <RightOutlined />
                </span>
              </span>
            </div>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table :data-source="record.staffAssignmentParts" :columns="staffcolumns" rowKey="id" :pagination="false"
              class="staffClass staffClass-assginment" :showHeader="false">
              <template #clientName>
                {{ record.account }}
              </template>
              <template #engagementCode>
                {{ record.engagementCode }}
              </template>
              <template #engagementDescription>
                {{ record.engagementDescription }}
              </template>
              <template #actual_release_date>
                {{ record.actual_release_date }}
              </template>
              <template #type>
                <span>分</span>
              </template>
              <template #extensionAssumptionDate="{ text }">
                <span>{{ text? text : '-' }}</span>
              </template>
              <template #certainty="{ text }">
                <span>{{ text? text : '-' }}</span>
              </template>
            </a-table>
          </template>
          <template #extensionAssumptionDate="{ text }">
            <span>{{ text? text : '-' }}</span>
          </template>
          <template #certainty="{ text }">
            <span>{{ text? text : '-' }}</span>
          </template>
        </a-table>
      </div>
      <div v-if="data.workTaskList.length>0" class="mt12">
        <CardTitle>Task</CardTitle>
        <a-table class="mt12" rowKey="id" :data-source="workTaskList" :columns="workTaskColumns" :pagination="false"
          :scroll="{
            y: 200,
          }" />
      </div>
      <div v-if="data.demandList?.length>0" class="mt12">
        <CardTitle>Demand</CardTitle>
        <a-table class="mt12" rowKey="id" :data-source="demandList" :columns="demandColumns" :pagination="false"
                 :scroll="{
            y: 200,
            x: 800,
          }">
          <template #useStaffLength="{text}">
            {{text? (text.includes('个月')? text : text+'个月') : ''}}
          </template>
        </a-table>
      </div>
      <template #footer>
        <a-button v-if="data.status !== 'prohibit'" @click="handleCancel">取消</a-button>
        <a-button v-if="data.status !== 'prohibit'" type="primary" @click="handleConfirm">确定</a-button>
        <span v-else></span>
      </template>
    </a-modal>
  </div>
  <div ref="overlapTimeModalRef">
    <OverlapTimelines
        :getContainer="$refs.overlapTimeModalRef"
        v-model:visible="timeVisible"
        @cancel-time="cancelTimeModal"
        :data="data"
        v-if="timeVisible"
    ></OverlapTimelines>
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import { computed, defineComponent, ref } from "vue";
import { DownOutlined, RightOutlined } from "@ant-design/icons-vue";
//import { scrollToBottom, checkAndAddDates, sortByDisplayDate, calculateBufferDisplayDate, filterObjects } from "@/hooks/common.js"
import OverlapTimelines from "@/components/OverlapTimelines.vue";
export default defineComponent({
  name: "OverlapModal",
  components: {
    OverlapTimelines,
    RightOutlined, DownOutlined,
    CardTitle,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({
        status: "prohibit",
        assignmentList: [],
        workTaskList: [],
        overlapCommandList: [],
        currentformData: {},
        demandList: [],
      }),
    },
    taskType: {
      type: String,
      default: ''
    },
  },
  emits: ["update", "cancel", "confirm"],
  setup(props, { emit }) {
    //const moment = require('moment');

    const annualAndOtherCode = ['12', '13'];

    const statusTip = computed(() => {
      let tip;
      if (annualAndOtherCode.includes(props.taskType)) {
        tip = 'Annual Leave 或 Other Leave Task';
      } else {
        let assignment = props.data.assignmentList.length > 0 ? "Assignment" : "";
        let workTask = props.data.workTaskList.length > 0 ? "Task" : "";
        let demand = props.data.demandList?.length > 0 ? "需求匹配" : "";
        tip = `${assignment}${assignment && workTask ? "和" : ""}${workTask}${demand ? "和" : ""}${demand}`;
      }
      if (props.data.status === "prohibit") {
        return `顾问已有其他${tip}存在，重叠小时数大于8小时，无法继续，请   修改时间。`;
      } else if (props.data.status === "matchingOverlap") {
        return `顾问已有其他需求匹配，是否继续？`;
      } else {
        return `顾问已有其他${tip}存在，重叠小时数小于或等于8小时，是否继续？`;
      }
    })

    const assignmentList = computed(() => props.data.assignmentList)

    const workTaskList = computed(() => props.data.workTaskList)

    const demandList = computed(() => props.data.demandList)

    const overlapCommandList = computed(() => props.data.overlapCommandList?.length > 0 ? props.data.overlapCommandList.filter(item => item.num !== 0 && item.sumHoursPerDay).map(item => {
      if (item.assignmentOverlap) {
        item.assignmentOverlap.clientName = props.data.currentformData.clientName
        item.assignmentOverlap.engagementCode = props.data.currentformData.engagementCode
        item.assignmentOverlap.engagementDescription = props.data.currentformData.engagementDescription
      }
      return {
        assignmentOverlap: item.assignmentOverlap ? [item.assignmentOverlap] : [],
        workTask: item.workTask ? [item.workTask] : [],
        assignmentOverlapList: item.assignmentOverlapList || [],
        workTaskList: item.workTaskList || [],
        startDate: item.startDate,
        endDate: item.endDate,
        sumHoursPerDay: item.sumHoursPerDay,
        num: item.num,
      }
    }) : [])

    const assignmentColumns = [
      {
        title: " ",
        dataIndex: "RowRender",
        key: "RowRender",
        width: "5%",
        slots: { customRender: "RowRender" },
      },
      {
        title: "Client Name",
        dataIndex: "account",
        key: "clientName",
        width: "10%",
        ellipsis: true,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        width: "15%",
        ellipsis: true,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        width: "15%",
        ellipsis: true,
      },
      {
        title: "Assignment Segmentation Type",
        dataIndex: "type",
        key: "type",
        width: "20%",
        ellipsis: true,
        slots: { customRender: "type" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "on_board_date",
        key: "on_board_date",
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "segmentationEndDate",
        key: "segmentationEndDate",
        width: "20%",
        ellipsis: true,
        slots: { customRender: "segmentationEndDate" },
      },
      {
        title: "Actual Release Date",
        dataIndex: "actual_release_date",
        key: "actual_release_date",
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        width: "15%",
        ellipsis: true,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 170,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        width: 100,
        slots: { customRender: "certainty" }
      },
    ]
    //分段
    const staffcolumns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: "5%",
      },
      {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        width: "10%",
        ellipsis: true,
        slots: { customRender: "clientName" },
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        width: "15%",
        ellipsis: true,
        slots: { customRender: "engagementCode" },
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        width: "15%",
        ellipsis: true,
        slots: { customRender: "engagementDescription" },
      },
      {
        title: "Assignment Segmentation Type",
        dataIndex: "type",
        key: "type",
        width: "20%",
        ellipsis: true,
        slots: { customRender: "type" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "startDate",
        key: "startDate",
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actual_release_date",
        key: "actual_release_date",
        width: "20%",
        ellipsis: true,
        slots: { customRender: "actual_release_date" },
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        width: "15%",
        ellipsis: true,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 170,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        width: 100,
        slots: { customRender: "certainty" }
      },
    ]
    const workTaskColumns = [
      {
        title: "Task Type",
        dataIndex: "taskType",
        key: "taskType",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Task Detail",
        dataIndex: "taskDetail",
        key: "taskDetail",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Start Date",
        dataIndex: "beginDate",
        key: "beginDate",
        width: 100,
        ellipsis: true,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hours",
        key: "hours",
        width: 100,
        ellipsis: true,
      },
    ]

    const demandColumns = [
      {
        title: "Demand Channel",
        dataIndex: "demandChannel",
        key: "demandChannel",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Demand Number",
        dataIndex: "demandNumber",
        key: "demandNumber",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Demand Name",
        dataIndex: "demandName",
        key: "demandName",
        width: 100,
        ellipsis: true,
      },
      {
        title: "ARVE Assumption Start Date",
        dataIndex: "arveAssumptionStartDate",
        key: "arveAssumptionStartDate",
        width: 150,
        ellipsis: true,
      },
      {
        title: "ARVE Assumption %",
        dataIndex: "arveAssumptionProportion",
        key: "arveAssumptionProportion",
        width: 100,
        ellipsis: true,
      },
      {
        title: "预计用人时长",
        dataIndex: "useStaffLength",
        key: "useStaffLength",
        width: 100,
        ellipsis: true,
        slots: { customRender: "useStaffLength" },
      },
    ]

    //超8小重叠事件列表
    const curExpandedRowKeys = ref([])
    const segmentedEditingClick = (rowkey) => {
      if (curExpandedRowKeys.value.length > 0) {
        let index = curExpandedRowKeys.value.indexOf(rowkey);
        if (index > -1) {
          curExpandedRowKeys.value.splice(index, 1);
        } else {
          //curExpandedRowKeys.value.splice(0, curExpandedRowKeys.value.length);
          curExpandedRowKeys.value.push(rowkey);
        }
      } else {
        curExpandedRowKeys.value.push(rowkey);
      }
    }

    const curExpandedAssignment = ref([])
    const assignmentSegmentedClick = (rowkey) => {
      if (curExpandedAssignment.value.length > 0) {
        let index = curExpandedAssignment.value.indexOf(rowkey);
        if (index > -1) {
          curExpandedAssignment.value.splice(index, 1);
        } else {
          //curExpandedRowKeys.value.splice(0, curExpandedRowKeys.value.length);
          curExpandedAssignment.value.push(rowkey);
        }
      } else {
        curExpandedAssignment.value.push(rowkey);
      }
    }
    const overlapEventsColumns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 100,
        slots: { customRender: "RowRender" },
      },
      {
        title: "重叠时间",
        dataIndex: "a",
        key: "a",
        width: 250,
        ellipsis: true,
        slots: { customRender: "overlaptime" },
      },
      {
        title: "重叠小时数",
        dataIndex: "sumHoursPerDay",
        key: "sumHoursPerDay",
        width: 250,
        ellipsis: true,
      },
      {
        title: "重叠调令和任务数",
        dataIndex: "num",
        key: "num",
        ellipsis: true,
      },
    ]

    const currentAssignmentColumns = [
      {
        title: " ",
        dataIndex: "status",
        key: "status",
        width: 20,
        slots: { customRender: "status" },
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Client</div>
        //         <div>Name</div>
        //       </div>
        //   );
        // },
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        width: 100,
        ellipsis: true,
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Engagement</div>
        //         <div>Code</div>
        //       </div>
        //   );
        // },
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        width: 130,
        ellipsis: true,
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Engagement</div>
        //         <div>Description</div>
        //       </div>
        //   );
        // },
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        width: 150,
        ellipsis: true,
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Assignment</div>
        //         <div>Segmentation Type</div>
        //       </div>
        //   );
        // },
        title: "Assignment Segmentation Type",
        dataIndex: "assignmentSegmentationType",
        key: "assignmentSegmentationType",
        width: 200,
        ellipsis: true,
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Project Onboard</div>
        //         <div>Date</div>
        //       </div>
        //   );
        // },
        title: "Project Onboard Date",
        dataIndex: "onBoardDate",
        key: "onBoardDate",
        width: 150,
        ellipsis: true,
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Segmentation End</div>
        //         <div>Date</div>
        //       </div>
        //   );
        // },
        title: "Segmentation End Date",
        dataIndex: "segmentationEndDate",
        key: "segmentationEndDate",
        width: 150,
        ellipsis: true,
      },
      {
        // title: () => {
        //   return (
        //       <div>
        //         <div>Actual Release</div>
        //         <div>Date</div>
        //       </div>
        //   );
        // },
        title: "Actual Release Date",
        dataIndex: "actualReleaseDate",
        key: "actualReleaseDate",
        width: 150,
        ellipsis: true,
      },
      {

        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 150,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 170,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        slots: { customRender: "certainty" }
      },
    ]
    const currentTaskColumns = [
      {
        title: " ",
        dataIndex: "status",
        key: "status",
        width: 20,
        slots: { customRender: "status" },
      },
      {
        title: "Task Type",
        dataIndex: "taskType",
        key: "taskType",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Task Detail",
        dataIndex: "taskDetial",
        key: "taskDetial",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Start Date",
        dataIndex: "beginDate",
        key: "beginDate",
        width: 100,
        ellipsis: true,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hours",
        key: "hours",
        width: 100,
        ellipsis: true,
      },
    ]
    const overlapAssignmentColumns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 20,
        slots: { customRender: "status" },
      },
      {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        width: 130,
        ellipsis: true,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Assignment Segmentation Type",
        dataIndex: "assignmentSegmentationType",
        key: "assignmentSegmentationType",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Project Onboard Date",
        dataIndex: "onBoardDate",
        key: "onBoardDate",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "segmentationEndDate",
        key: "segmentationEndDate",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actualReleaseDate",
        key: "actualReleaseDate",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 150,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 170,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        slots: { customRender: "certainty" }
      },
    ]
    const overlapTaskColumns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 20,
        slots: { customRender: "status" },
      },
      {
        title: "Task Type",
        dataIndex: "taskType",
        key: "taskType",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Task Detail",
        dataIndex: "taskDetail",
        key: "taskDetail",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Start Date",
        dataIndex: "beginDate",
        key: "beginDate",
        width: 100,
        ellipsis: true,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hours",
        key: "hours",
        width: 100,
        ellipsis: true,
      },
    ]

    const handleCancel = () => {
      curExpandedRowKeys.value = []
      curExpandedAssignment.value = []
      emit('update:visible', false)
      emit('cancel')
    }
    const handleConfirm = () => {
      emit('update:visible', false)
      emit('confirm')
    }

    const checkEightTime = () => {
      timeVisible.value = true
    }
    const timeVisible = ref(false)
    const cancelTimeModal = () => {
      timeVisible.value = false
    }

    return {
      statusTip,
      annualAndOtherCode,
      assignmentList,
      workTaskList,
      demandList,
      assignmentColumns,
      workTaskColumns,
      demandColumns,
      handleCancel,
      handleConfirm,
      overlapEventsColumns,
      // overlapCurrentColumns,
      overlapAssignmentColumns,
      overlapTaskColumns,
      curExpandedRowKeys,
      segmentedEditingClick,
      overlapCommandList,
      currentAssignmentColumns,
      currentTaskColumns,
      staffcolumns,
      assignmentSegmentedClick,
      curExpandedAssignment,
      checkEightTime,
      timeVisible,
      cancelTimeModal,
    }
  }
})

// const annualAndOtherCode = ['12','13'];
// export default {
//   name: "OverlapModal",
//   components: {
//     CardTitle,
//   },
//   props: {
//     visible: {
//       type: Boolean,
//       default: false,
//     },
//     data: {
//       type: Object,
//       default: () => ({
//         status: "prohibit",
//         assignmentList: [],
//         workTaskList: [],
//       }),
//     },
//     taskType:{
//       type:String,
//       default:''
//     }
//   },
//   data () {
//     return {
//       assignmentColumns: [
//         {
//           title: "Account",
//           dataIndex: "account",
//           key: "clientName",
//           width: "10%",
//           ellipsis: true,
//         },
//         {
//           title: "Engagement Code",
//           dataIndex: "engagementCode",
//           key: "engagementCode",
//           width: "15%",
//           ellipsis: true,
//         },
//         {
//           title: "Engagement Description",
//           dataIndex: "engagementDescription",
//           key: "engagementDescription",
//           width: "20%",
//           ellipsis: true,
//         },
//         {
//           title: "Project Onboard Date",
//           dataIndex: "onBoardDate",
//           key: "onBoardDate",
//           width: "20%",
//           ellipsis: true,
//         },
//         {
//           title: "Actual Release Date",
//           dataIndex: "actualReleaseDate",
//           key: "actualReleaseDate",
//           width: "20%",
//           ellipsis: true,
//         },
//         {
//           title: "Hours Per Day",
//           dataIndex: "hoursPerDay",
//           key: "hoursPerDay",
//           width: "15%",
//           ellipsis: true,
//         },
//       ],
//       workTaskColumns: [
//         {
//           title: "Task Type",
//           dataIndex: "taskType",
//           key: "taskType",
//           width: 100,
//           ellipsis: true,
//         },
//         {
//           title: "Task Detail",
//           dataIndex: "taskDetail",
//           key: "taskDetail",
//           width: 100,
//           ellipsis: true,
//         },
//         {
//           title: "Start Date",
//           dataIndex: "beginDate",
//           key: "beginDate",
//           width: 100,
//           ellipsis: true,
//         },
//         {
//           title: "End Date",
//           dataIndex: "endDate",
//           key: "endDate",
//           width: 100,
//           ellipsis: true,
//         },
//         {
//           title: "Hours Per Day",
//           dataIndex: "hours",
//           key: "hours",
//           width: 100,
//           ellipsis: true,
//         },
//       ],
//     }
//   },
//   computed: {
//     title () {
//       let tip;
//       if(annualAndOtherCode.includes(this.taskType)){
//         tip = 'Annual Leave 或 Other Leave Task';
//       }else{
//         let assignment = this.data.assignmentList.length > 0  ? "Assignment" : "";
//         let workTask = this.data.workTaskList.length > 0  ? "Task" : "";
//         tip = `${assignment}${assignment && workTask ? "和" : ""}${workTask}`;
//       }
//       if (this.data.status === "prohibit") {
//         return `顾问已有其他${tip}存在，重叠小时数大于8小时，无法继续，请修改时间。`;
//       } else {
//         return `顾问已有其他${tip}存在，重叠小时数小于或等于8小时，是否继续？`;
//       }
//     },
//   },
//   methods: {
//     handleCancel () {
//       this.$emit('update:visible', false)
//       this.$emit('cancel')
//     },
//     handleConfirm () {
//       this.$emit('update:visible', false)
//       this.$emit('confirm')
//     },
//   }
// };
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";

.overlap-container {
  .status-icon {
    font-size: 16px;
    color: #58b99e;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.true {
      color: #FFB18F;
    }

    &.prohibit {
      color: #dc5e4f;
    }

    &.matchingOverlap {
      color: #58b99e;
    }
  }
  :deep(.ant-modal-body){
    max-height:79vh!important;
  }
}

:deep(tr.ant-table-expanded-row) {
  >td {
    padding: 0 !important;
  }
}

.staffClass {
  :deep(.ant-table .ant-table-tbody > tr > td) {
    padding: 0.41667vw 0.83333vw !important
  }
}

:deep(.ant-modal-body) {
  max-height: 80vh;
  overflow: auto;
}

.staffClass-assginment {
  margin: 0 !important;

  :deep(tr.ant-table-expanded-row td > .ant-table-wrapper) {
    margin: 0;
  }
}

</style>
