<template>
  <div ref="overlapTimeModalRef" class="overlap-time">
    <a-modal :getContainer="() => $refs.overlapTimeModalRef" width="90vw" :visible="visible" @cancel="handleCancel"
        title="8小时重叠时间轴示意图" centered :footer="null" :z-index="10001" :bodyStyle="{ height: '1500px' }">
      <div class="eightTimeLine" id="eightTimeLine" ref="eightTimeLineRef">
        <!-- 图例说明 -->
        <div class="explain" :style="{ width: `${basicTimeLineWidth+fcstExtensionAssumptionDateWidth}px` }">
          <div>
            <div class="commonItem mainItem">Current Assignment</div>
            <div class="commonItem assignItem">Other Assignment</div>
            <div class="otherItem">Other Task</div>
          </div>
          <div>
            <div class="commonItem current-mainItem">Current  Extension Assumption</div>
            <div class="commonItem extension-assignItem">Other Extension Assumption</div>
            <div class="demand-otherItem">Demand Proposal Assumption</div>
          </div>
        </div>
        <!-- 需求匹配上项目假设时间轴 -->
        <div class="demandTimeLine" :style="{ width: `${basicTimeLineWidth}px` }" v-if="timeDemandList.length>0">
          <div v-for="(item, index) in timeDemandList" :key="index" class="demandTime" :style="{
              width: `${item.timeDifference * dayStepLength}px`,
              marginLeft: `${item.bufferStartDate * dayStepLength}px`
            }">
            <div class="demandPopover">
              <!-- 两端顶点左顶点 -->
              <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                         :getPopupContainer="() => $refs.eightTimeLineRef">
                <template #content>
                  <div>
                    <div class="container">
                      <div class="leftArea">Demand Channel:</div>
                      <div class="rightArea">{{ item.demandChannel }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">Demand Number:</div>
                      <div class="rightArea">{{ item.demandNumber }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">Demand Name:</div>
                      <div class="rightArea">{{ item.demandName }}</div>
                    </div>
                    <div class="container largeContainer">
                      <div class="leftArea">ARVE Assumption Start Date:</div>
                      <div class="rightArea">{{ item.arveAssumptionStartDate }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">ARVE Assumption %:</div>
                      <div class="rightArea">{{ item.arveAssumptionProportion }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">预计用人时长:</div>
                      <div class="rightArea">{{ item.useStaffLength }}</div>
                    </div>
                  </div>
                </template>
                <div class="leftPoint">
                    <div class="timeLeft">{{ item.demandName }}</div>
                    <div class="timeBottom">{{ item.startDate }}</div>
                </div>
              </a-popover>
              <!-- 两端顶点右顶点 -->
              <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                         :getPopupContainer="() => $refs.eightTimeLineRef">
                <template #content>
                  <div>
                    <div class="container">
                      <div class="leftArea">Demand Channel:</div>
                      <div class="rightArea">{{ item.demandChannel }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">Demand Number:</div>
                      <div class="rightArea">{{ item.demandNumber }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">Demand Name:</div>
                      <div class="rightArea">{{ item.demandName }}</div>
                    </div>
                    <div class="container largeContainer">
                      <div class="leftArea">ARVE Assumption Start Date:</div>
                      <div class="rightArea">{{ item.arveAssumptionStartDate }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">ARVE Assumption %:</div>
                      <div class="rightArea">{{ item.arveAssumptionProportion }}</div>
                    </div>
                    <div class="container">
                      <div class="leftArea">预计用人时长:</div>
                      <div class="rightArea">{{ item.useStaffLength }}</div>
                    </div>
                  </div>
                </template>
                <div class="rightPoint">

                    <div class="timeDisBottom">{{ item.endDate }}</div>

                </div>
              </a-popover>
            </div>
          </div>
        </div>
        <!-- 额外调令轴 -->
        <div class="extraTimeLine" :style="{ width: `${basicTimeLineWidth+fcstExtensionAssumptionDateWidth}px` }" >
          <div v-for="(item, index) in actualTimeTaskList" :key="index"
               :class="[item.task == true ? 'task' : 'specialTimeLine']" :style="{
              width: `${item.timeDifference === 1 ? 0 : item.timeDifference * dayStepLength + item.fcstTimeDifference}px`,
              marginLeft: `${item.bufferStartDate * dayStepLength}px`,
            }">
            <div  v-if="item.startDate<mainTimeEndTime">
              <div :class="[item.task == true ? 'extraBasicTask' : 'extrabasic']" :style="{width: `${item.timeDifference * dayStepLength}px`,position: 'relative'}">
                <div class="extraBasicPopover" >
                  <!-- 两端顶点左顶点 -->
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div>
                        <div v-if="item.task == true">
                          <div class="container">
                            <div class="leftArea">Task Type:</div>
                            <div class="rightArea">{{ item.taskType }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Task Detail:</div>
                            <div class="rightArea">{{ item.taskDetail }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Start Date:</div>
                            <div class="rightArea">{{ item.startDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">End Date:</div>
                            <div class="rightArea">{{ item.endDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Hours Per Day:</div>
                            <div class="rightArea">{{ item.hours }}</div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="container">
                            <div class="leftArea">Client Name:</div>
                            <div class="rightArea">{{ item.clientName }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Engagement Code:</div>
                            <div class="rightArea">{{ item.Enagement }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Assignment Segmentation Type:</div>
                            <div class="rightArea">{{ item.type }}</div>
                          </div>
                          <div class="container largeContainer">
                            <div class="leftArea">Engagement Description:</div>
                            <div class="rightArea">{{ item.EngagementDescription }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Project Onboard Date:</div>
                            <div class="rightArea">{{ item.ProjectOnboardDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Actual Release Date:</div>
                            <div class="rightArea">{{ item.endDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">HoursPerDay:</div>
                            <div class="rightArea">{{ item.hoursPerDay }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">FCST Extension Assumption Date:</div>
                            <div class="rightArea">{{ item.extensionAssumptionDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">确定性:</div>
                            <div class="rightArea">{{ item.certainty }}</div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="leftPoint">
                      <template v-if="item.task == true">
                        <div class="timeLeft">{{ item.taskType }}</div>
                        <div class="timeBottom">{{ item.startDate }}</div>
                      </template>
                      <template v-else>
                        <div class="timeLeft">{{ item.clientName }}</div>
                        <div class="timeBottom">{{ item.startDate }}</div>
                      </template>
                    </div>
                  </a-popover>
                  <!-- 两端顶点右顶点 -->
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div>
                        <div v-if="item.task == true">
                          <div class="container">
                            <div class="leftArea">Task Type:</div>
                            <div class="rightArea">{{ item.taskType }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Task Detail:</div>
                            <div class="rightArea">{{ item.taskDetail }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Start Date:</div>
                            <div class="rightArea">{{ item.startDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">End Date:</div>
                            <div class="rightArea">{{ item.endDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Hours Per Day:</div>
                            <div class="rightArea">{{ item.hours }}</div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="container">
                            <div class="leftArea">Client Name:</div>
                            <div class="rightArea">{{ item.clientName }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Engagement Code:</div>
                            <div class="rightArea">{{ item.Enagement }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Assignment Segmentation Type:</div>
                            <div class="rightArea">{{ item.type }}</div>
                          </div>
                          <div class="container largeContainer">
                            <div class="leftArea">Engagement Description:</div>
                            <div class="rightArea">{{ item.EngagementDescription }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Project Onboard Date:</div>
                            <div class="rightArea">{{ item.ProjectOnboardDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Actual Release Date:</div>
                            <div class="rightArea">{{ item.endDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">HoursPerDay:</div>
                            <div class="rightArea">{{ item.hoursPerDay }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">FCST Extension Assumption Date:</div>
                            <div class="rightArea">{{ item.extensionAssumptionDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">确定性:</div>
                            <div class="rightArea">{{ item.certainty }}</div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="rightPoint">
                      <template v-if="item.task == true">
                        <div class="timeDisBottom">{{ item.endDate}}</div>
                      </template>
                      <template v-else>
                        <div class="timeDisBottom">{{ item.endDate}}</div>
                      </template>
                    </div>
                  </a-popover>
                </div>
              </div>
              <div v-if="item.extensionAssumptionDate" class="extraFcst" :style="{width: `${item.fcstTimeDifference * dayStepLength}px`,left: `${item.timeDifference * dayStepLength}px`,position: 'relative'}">
                <div class="extrafcstPopover">
                  <!-- FCST Extension Assumption Date两端顶点右顶点 -->
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div>
                        <div class="container">
                          <div class="leftArea">Client Name:</div>
                          <div class="rightArea">{{ item.clientName }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Engagement Code:</div>
                          <div class="rightArea">{{ item.Enagement }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Assignment Segmentation Type:</div>
                          <div class="rightArea">{{ item.type }}</div>
                        </div>
                        <div class="container largeContainer">
                          <div class="leftArea">Engagement Description:</div>
                          <div class="rightArea">{{ item.EngagementDescription }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Project Onboard Date:</div>
                          <div class="rightArea">{{ item.ProjectOnboardDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Actual Release Date:</div>
                          <div class="rightArea">{{ item.endDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">HoursPerDay:</div>
                          <div class="rightArea">{{ item.hoursPerDay }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">FCST Extension Assumption Date:</div>
                          <div class="rightArea">{{ item.extensionAssumptionDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">确定性:</div>
                          <div class="rightArea">{{ item.certainty }}</div>
                        </div>
                      </div>
                    </template>
                    <div class="fcstRightPoint">
                        <div class="timeDisBottom">{{ item.extensionAssumptionDate}}</div>
                    </div>
                  </a-popover>
                </div>
              </div>

              <!-- 分段顶点 -->
              <template v-if="item.staffAssignmentParts && item.staffAssignmentParts.length > 0">
                <div v-for="(jtem, jndex) in item.staffAssignmentParts" :key="jndex" class="specialDot" :style="{
                  marginLeft: `${jtem.bufferStartDate * dayStepLength - item.bufferStartDate * dayStepLength}px`,
                  width: `${jtem.timeDifference * dayStepLength}px`
                }">
                  <!-- 两端顶点左顶点 -->
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div>
                        <div class="container">
                          <div class="leftArea">Client Name:</div>
                          <div class="rightArea">{{ item.clientName }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Engagement Code:</div>
                          <div class="rightArea">{{ item.Enagement }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Assignment Segmentation Type:</div>
                          <div class="rightArea">分</div>
                        </div>
                        <div class="container largeContainer">
                          <div class="leftArea">Engagement Description:</div>
                          <div class="rightArea">{{ item.EngagementDescription }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Project Onboard Date:</div>
                          <div class="rightArea">{{ jtem.startDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Segmentation End Date:</div>
                          <div class="rightArea">{{ jtem.endDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">HoursPerDay:</div>
                          <div class="rightArea">{{ jtem.hoursPerDay }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">FCST Extension Assumption Date:</div>
                          <div class="rightArea">{{ item.extensionAssumptionDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">确定性:</div>
                          <div class="rightArea">{{ item.certainty }}</div>
                        </div>
                      </div>
                    </template>
                    <div class="leftPoint" style="background-Color:rgb(70, 70, 183);top:-5px">
                    </div>
                  </a-popover>
                  <!-- 两端顶点右顶点 -->
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div>
                        <div class="container">
                          <div class="leftArea">Client Name:</div>
                          <div class="rightArea">{{ item.clientName }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Engagement Code:</div>
                          <div class="rightArea">{{ item.Enagement }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Assignment Segmentation Type:</div>
                          <div class="rightArea">分</div>
                        </div>
                        <div class="container largeContainer">
                          <div class="leftArea">Engagement Description:</div>
                          <div class="rightArea">{{ item.EngagementDescription }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Project Onboard Date:</div>
                          <div class="rightArea">{{ jtem.startDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">Segmentation End Date:</div>
                          <div class="rightArea">{{ jtem.endDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">HoursPerDay:</div>
                          <div class="rightArea">{{ jtem.hoursPerDay }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">FCST Extension Assumption Date:</div>
                          <div class="rightArea">{{ item.extensionAssumptionDate }}</div>
                        </div>
                        <div class="container">
                          <div class="leftArea">确定性:</div>
                          <div class="rightArea">{{ item.certainty }}</div>
                        </div>
                      </div>
                    </template>
                    <div class="rightPoint" style="background-Color:rgb(70, 70, 183);top:-5px">
                    </div>
                  </a-popover>

                  <!-- 时间段 -->
                  <div class="pieceArray">
                    <template v-if="jtem.timeDifference < 1">
                      <div style="font-size: 0.5rem" class="pieceSpecial">{{ jtem.startDate.substr(5) }} - {{ jtem.endDate.substr(5) }}</div>
                    </template>
                    <template v-else>
                      <div style="font-size: 0.5rem;">{{ jtem.startDate.substr(5) }} - {{ jtem.endDate.substr(5) }}</div>
                    </template>

                  </div>
                </div>
              </template>

              <!-- 超过八小时调令,如果长于当前时间轴则只体现在时间轴上 -->
              <div v-for="(exem, endex) in exceedTimeList" class="exceedRed" :key="endex" :style="{
                width: `${item.timeDifference === 1 ? 0 : (item.timeDifference * dayStepLength < exem.timeDifference * dayStepLength ? item.timeDifference * dayStepLength : exem.timeDifference * dayStepLength)}px`,
                marginLeft: `${exem.bufferStartDate * dayStepLength - item.bufferStartDate * dayStepLength}px`,
                display:
                  // 判断在不在对应分调令的轴上，如果当前红点大于结束时间,距离右侧的长度则不显示，或者小于开始的长度
                  ((item.bufferStartDate * dayStepLength + item.timeDifference * dayStepLength) <= exem.bufferStartDate * dayStepLength)
                    || (exem.bufferStartDate * dayStepLength < item.bufferStartDate * dayStepLength)
                    ? 'none' : 'block'
              }">

                <template v-if="exem.timeDifference <= 1">
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div style="color:red"> 超8h：{{ exem.startDate.substr(5) }}（{{
                          exem.hoursPerDay }}）</div>
                    </template>
                    <div class="specialExceed"></div>
                  </a-popover>
                </template>
                <template v-else>
                  <div class="exceedSplit">{{ exem.startDate.substr(5) }} - {{ exem.endDate.substr(5) }}（{{
                      exem.hoursPerDay }}）</div>
                </template>
              </div>
            </div>
            <div  v-else>
              <div :class="[item.task == true ? 'extraBasicTask' : 'extrabasic']" :style="{position: 'relative'}">
                <div class="extraBasicPopover" >
                  <!-- 两端顶点左顶点 -->
                  <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                            :getPopupContainer="() => $refs.eightTimeLineRef">
                    <template #content>
                      <div>
                        <div v-if="item.task == true">
                          <div class="container">
                            <div class="leftArea">Task Type:</div>
                            <div class="rightArea">{{ item.taskType }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Task Detail:</div>
                            <div class="rightArea">{{ item.taskDetail }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Start Date:</div>
                            <div class="rightArea">{{ item.startDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">End Date:</div>
                            <div class="rightArea">{{ item.endDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Hours Per Day:</div>
                            <div class="rightArea">{{ item.hours }}</div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="container">
                            <div class="leftArea">Client Name:</div>
                            <div class="rightArea">{{ item.clientName }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Engagement Code:</div>
                            <div class="rightArea">{{ item.Enagement }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Assignment Segmentation Type:</div>
                            <div class="rightArea">{{ item.type }}</div>
                          </div>
                          <div class="container largeContainer">
                            <div class="leftArea">Engagement Description:</div>
                            <div class="rightArea">{{ item.EngagementDescription }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Project Onboard Date:</div>
                            <div class="rightArea">{{ item.ProjectOnboardDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">Actual Release Date:</div>
                            <div class="rightArea">{{ item.endDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">HoursPerDay:</div>
                            <div class="rightArea">{{ item.hoursPerDay }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">FCST Extension Assumption Date:</div>
                            <div class="rightArea">{{ item.extensionAssumptionDate }}</div>
                          </div>
                          <div class="container">
                            <div class="leftArea">确定性:</div>
                            <div class="rightArea">{{ item.certainty }}</div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="leftPoint">
                      <template v-if="item.task == true">
                        <div class="timeLeft">{{ item.taskType }}</div>
                        <div class="timeBottom">{{ item.startDate }}</div>
                      </template>
                      <template v-else>
                        <div class="timeLeft">{{ item.clientName }}</div>
                        <div class="timeBottom">{{ item.startDate }}</div>
                      </template>
                    </div>
                  </a-popover>
                </div>
              </div>
              <div v-for="(exem, endex) in exceedTimeList" class="exceedRed" :key="endex" :style="{
              width: `${item.timeDifference === 1 ? 0 : (item.timeDifference * dayStepLength < exem.timeDifference * dayStepLength ? item.timeDifference * dayStepLength : exem.timeDifference * dayStepLength)}px`,
              marginLeft: `${exem.bufferStartDate * dayStepLength - item.bufferStartDate * dayStepLength}px`,
              display:
                // 判断在不在对应分调令的轴上，如果当前红点大于结束时间,距离右侧的长度则不显示，或者小于开始的长度
                ((item.bufferStartDate * dayStepLength + item.timeDifference * dayStepLength) <= exem.bufferStartDate * dayStepLength)
                  || (exem.bufferStartDate * dayStepLength < item.bufferStartDate * dayStepLength)
                  ? 'none' : 'block'
            }">

              <template v-if="exem.timeDifference <= 1">
                <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                           :getPopupContainer="() => $refs.eightTimeLineRef">
                  <template #content>
                    <div style="color:red"> 超8h：{{ exem.startDate.substr(5) }}（{{
                        exem.hoursPerDay }}）</div>
                  </template>
                  <div class="specialExceed"></div>
                </a-popover>
              </template>
              <template v-else>
                <div class="exceedSplit">{{ exem.startDate.substr(5) }} - {{ exem.endDate.substr(5) }}（{{
                    exem.hoursPerDay }}）</div>
              </template>
              </div>
            </div>
          </div>
        </div>

        <!-- 基础调令轴 -->
        <div style="position: relative">
          <div class="basicTimeLine" :style="{ width: `${basicTimeLineWidth}px` }">
            <div class="basicTimeArray" :style="{ width: `${basicTimeLineWidth}px` }">
              <div class="basicTimeStart">{{ mainTimeStartTime }}</div>
              <div class="basicTimeEnd">{{ mainTimeEndTime }}</div>
            </div>
          </div>
          <div v-if="fcstDate" class="fcstBasicTimeLine" :style="{ width: `${fcstExtensionAssumptionDateWidth}px`,left:`${basicTimeLineWidth}px` }">
            <div class="basicTimeArray" :style="{ width: `${fcstExtensionAssumptionDateWidth}px` }">
              <div class="basicTimeEnd">{{ fcstDate }}</div>
            </div>
          </div>
          <div v-for="(exem, endex) in exceedTimeList" class="exceedRed" :key="endex" :style="{
            width: `${exem.timeDifference === 1 ? 0 : exem.timeDifference * dayStepLength}px`,
            marginLeft: `${exem.bufferStartDate * dayStepLength}px`,
          }">
            <template v-if="exem.timeDifference <= 1">
              <a-popover placement="bottom" overlayClassName="specialTimeLinePopover"
                         :getPopupContainer="() => $refs.eightTimeLineRef">
                <template #content>
                  <div style="color:red"> 超8h：{{ exem.startDate.substr(5) }}（{{
                      exem.hoursPerDay }}）</div>
                </template>
                <div class="specialExceed"></div>
              </a-popover>
            </template>
            <template v-else>
              <div class="exceedSplit">{{ exem.startDate.substr(5) }} - {{ exem.endDate.substr(5) }}（{{
                  exem.hoursPerDay }}）</div>
            </template>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, ref, watch} from "vue";
//import CardTitle from "@/components/CardTitle.vue";
import moment from "moment/moment";
import {
  calculateBufferDisplayDate,
  checkAndAddDates,
  filterObjects,
  //scrollToBottom,
  sortByDisplayDate
} from "@/hooks/common";

export default defineComponent({
  name: "OverlapTimelines",
  components: {},
  props: {
    visible: Boolean,
    data: {
      type: Object,
      default: () => ({
        status: "prohibit",
        assignmentList: [],
        workTaskList: [],
        overlapCommandList: [],
        currentformData: {},
        demandList: [],
      }),
    },
  },
  emits: ["cancel-time"],
  setup(props, {emit}) {
    // 主调令之间差了天
    let dayDifference = ref()
    //  主调令的长度
    let basicTimeLineWidth = ref()
    //  每一天的长度
    let dayStepLength = ref()
    // 主调令的开始和结束时间
    let mainTimeStartTime = ref()
    let mainTimeEndTime = ref()
    // 超出八小时调令的时间段
    let exceedTimeList = ref()
    //  FCST Extension Assumption Date的长度
    let fcstExtensionAssumptionDateWidth = ref()
    let fcstDate = ref()
    watch(
        () => props.data.overlapCommandList,
        (val) => {
          if(val) {
            let overlapCommandList =  props.data.overlapCommandList?.length > 0 ? props.data.overlapCommandList.filter(item => item.num !== 0).map(item => {
              if (item.assignmentOverlap) {
                item.assignmentOverlap.clientName = props.data.currentformData.clientName
                item.assignmentOverlap.engagementCode = props.data.currentformData.engagementCode
                item.assignmentOverlap.engagementDescription = props.data.currentformData.engagementDescription
              }
              return {
                assignmentOverlap: item.assignmentOverlap ? [item.assignmentOverlap] : [],
                workTask: item.workTask ? [item.workTask] : [],
                assignmentOverlapList: item.assignmentOverlapList || [],
                workTaskList: item.workTaskList || [],
                startDate: item.startDate,
                endDate: item.endDate,
                sumHoursPerDay: item.sumHoursPerDay,
                num: item.num,
              }
            }) : []
            dayDifference.value = null
            basicTimeLineWidth.value = null
            dayStepLength.value = null
            mainTimeStartTime.value = null
            mainTimeEndTime.value = null
            exceedTimeList.value = null
            fcstExtensionAssumptionDateWidth.value = null
            fcstDate.value = null
            if (overlapCommandList && overlapCommandList.length) {
              let date = JSON.parse(localStorage.getItem('PreEightTimeLine')) || []
              const startDate = date[0]
              const endDate = date[1]
              mainTimeStartTime.value = startDate
              mainTimeEndTime.value = endDate
              //FCST Extension Assumption Date与enddate之间差了多少天计算渲染的长度
              let fcstEightTime = JSON.parse(localStorage.getItem('FcstEightTimeLine')) || null
              fcstDate.value = (fcstEightTime && moment(fcstEightTime).isAfter(endDate))? fcstEightTime : null
              let fcstDateDifference = (fcstDate.value && moment(fcstDate.value).isAfter(endDate))? moment(fcstDate.value).diff(endDate, 'day') : 0
              // 主调令之间差了多少天计算渲染的长度
              dayDifference.value = moment(endDate).diff(startDate, 'day')
              if (dayDifference.value * 7 > 650*5.0) {
                basicTimeLineWidth.value = dayDifference.value * 7*5.0
                dayStepLength.value = 7*5.0
                fcstExtensionAssumptionDateWidth.value = fcstDateDifference * 7 *5.0
              } else {
                if(dayDifference.value===0 && fcstDate.value) {
                  basicTimeLineWidth.value = 55*5.0
                  dayStepLength.value = basicTimeLineWidth.value
                  fcstExtensionAssumptionDateWidth.value = fcstDateDifference * dayStepLength.value
                } else {
                  basicTimeLineWidth.value = 650*5.0
                  dayStepLength.value = dayDifference.value===0? 650*5.0 : basicTimeLineWidth.value / dayDifference.value
                  fcstExtensionAssumptionDateWidth.value = fcstDateDifference * dayStepLength.value
                }
              }
              // 筛选超出8小时的List
              let exceedList = overlapCommandList.filter((item) => { return item.sumHoursPerDay > 8 }).map((jtem) => { return { startDate: jtem.startDate, endDate: jtem.endDate, hoursPerDay: jtem.sumHoursPerDay } })
              console.log('exceedlist',exceedList)
              console.log('步长',dayStepLength.value)
              exceedList = calculateBufferDisplayDate(checkAndAddDates(exceedList, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value), mainTimeStartTime.value)
              exceedTimeList.value = sortByDisplayDate(exceedList)
            }
          }
          },
        {immediate:true}

    )

    // watchEffect(() => {
    //   dayDifference.value = null
    //   basicTimeLineWidth.value = null
    //   dayStepLength.value = null
    //   mainTimeStartTime.value = null
    //   mainTimeEndTime.value = null
    //   exceedTimeList.value = null
    //   fcstExtensionAssumptionDateWidth.value = null
    //   fcstDate.value = null
    //   if (overlapCommandList.value && overlapCommandList.value.length) {
    //     let date = JSON.parse(localStorage.getItem('PreEightTimeLine')) || []
    //     const startDate = date[0]
    //     const endDate = date[1]
    //     mainTimeStartTime.value = startDate
    //     mainTimeEndTime.value = endDate
    //     //FCST Extension Assumption Date与enddate之间差了多少天计算渲染的长度
    //     let fcstEightTime = JSON.parse(localStorage.getItem('FcstEightTimeLine')) || null
    //     fcstDate.value = (fcstEightTime && moment(fcstEightTime).isAfter(endDate))? fcstEightTime : null
    //     let fcstDateDifference = (fcstDate.value && moment(fcstDate.value).isAfter(endDate))? moment(fcstDate.value).diff(endDate, 'day') : 0
    //     // 主调令之间差了多少天计算渲染的长度
    //     dayDifference.value = moment(endDate).diff(startDate, 'day')
    //     if (dayDifference.value * 7 > 650) {
    //       basicTimeLineWidth.value = dayDifference.value * 7
    //       dayStepLength.value = 7
    //       fcstExtensionAssumptionDateWidth.value = fcstDateDifference * 7
    //     } else {
    //       if(dayDifference.value===0 && fcstDate.value) {
    //         basicTimeLineWidth.value = 55
    //         dayStepLength.value = basicTimeLineWidth.value
    //         fcstExtensionAssumptionDateWidth.value = fcstDateDifference * dayStepLength.value
    //       } else {
    //         basicTimeLineWidth.value = 650
    //         dayStepLength.value = dayDifference.value===0? 650 : basicTimeLineWidth.value / dayDifference.value
    //         fcstExtensionAssumptionDateWidth.value = fcstDateDifference * dayStepLength.value
    //       }
    //     }
    //     // 筛选超出8小时的List
    //     let exceedList = overlapCommandList.value.filter((item) => { return item.sumHoursPerDay > 8 }).map((jtem) => { return { startDate: jtem.startDate, endDate: jtem.endDate, hoursPerDay: jtem.sumHoursPerDay } })
    //     exceedList = calculateBufferDisplayDate(checkAndAddDates(exceedList, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value), mainTimeStartTime.value)
    //     exceedTimeList.value = sortByDisplayDate(exceedList)
    //   }
    // })

    // 变形后的taskList
    let taskTimeList = ref([])
    // 变形后的副调令
    let otherTimeList = ref([])
    // 合并后的实际渲染
    let actualTimeTaskList = ref([])
    watch(
        () => props.data.assignmentList,
        (val) => {
          if(val) {
            taskTimeList.value = []
            otherTimeList.value = []
            actualTimeTaskList.value = []
            if (props.data.assignmentList && props.data.assignmentList.length) {
              // 从原数据中取出需要的数据
              let modifyList = props.data.assignmentList.map((item) => {
                    let list = []
                    if (item.staffAssignmentParts && item.staffAssignmentParts.length) {
                      // 这一步是根据主调令给每一个分段调令添加displayStartDate（自身分段调令早于主调令）这些，然后根据对应的displayDate
                      // 计算和主调令的偏移量
                      list = calculateBufferDisplayDate(checkAndAddDates(item.staffAssignmentParts, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value), mainTimeStartTime.value)
                      // 确定每一段分调令的长度，如果有displayDate那么则是按照display显示
                      list = sortByDisplayDate(list)
                      list = filterObjects(list, mainTimeStartTime.value, mainTimeEndTime.value, fcstDate.value)
                    }
                    return {
                      startDate: item.on_board_date,
                      endDate: item.actual_release_date,
                      clientName: item.account, Enagement: item.engagementCode,
                      EngagementDescription: item.engagementDescription,
                      ProjectOnboardDate: item.on_board_date,
                      hoursPerDay: item.hoursPerDay,
                      staffAssignmentParts: list,
                      type: '主',
                      extensionAssumptionDate: (item.extensionAssumptionDate && moment(item.extensionAssumptionDate).isAfter(item.actual_release_date))?
                          item.extensionAssumptionDate : null,
                      certainty: item.certainty,
                    }
                  }
              )
              modifyList = filterObjects(modifyList, mainTimeStartTime.value, mainTimeEndTime.value, fcstDate.value)
              // 检查和主调令的边界，并且计算内部的天数差据
              let checkList = checkAndAddDates(sortByDisplayDate(modifyList), mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
              // 计算和主调令的起始时间的偏移距离
              otherTimeList.value = calculateBufferDisplayDate(checkList, mainTimeStartTime.value)
            }
            // Task
            if (props.data.workTaskList && props.data.workTaskList.length) {
              // 从原数据中取出需要的数据
              let modifyList = props.data.workTaskList.map((item) => { return { startDate: item.beginDate, endDate: item.endDate, task: true, taskType: item.taskType, taskDetail: item.taskDetail, hours: item.hours } })
              modifyList = filterObjects(modifyList, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
              // 检查和主调令的边界，并且计算内部的天数差据
              modifyList = checkAndAddDates(modifyList, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
              let checkList = checkAndAddDates(sortByDisplayDate(modifyList), mainTimeStartTime.value, mainTimeEndTime.value)
              // 计算和主调令的起始时间的偏移距离
              taskTimeList.value = calculateBufferDisplayDate(checkList, mainTimeStartTime.value)
            }
            actualTimeTaskList.value = sortByDisplayDate([...otherTimeList.value, ...taskTimeList.value]).reverse()
            //scrollToBottom('eightTimeLine')
            console.log('时间',actualTimeTaskList.value.startDate,mainTimeEndTime)
          }
          },
        {immediate:true}
    )
    // watchEffect(() => {
    //   taskTimeList.value = []
    //   otherTimeList.value = []
    //   actualTimeTaskList.value = []
    //   if (assignmentList.value && assignmentList.value.length) {
    //     // 从原数据中取出需要的数据
    //     let modifyList = assignmentList.value.map((item) => {
    //           let list = []
    //           if (item.staffAssignmentParts && item.staffAssignmentParts.length) {
    //             // 这一步是根据主调令给每一个分段调令添加displayStartDate（自身分段调令早于主调令）这些，然后根据对应的displayDate
    //             // 计算和主调令的偏移量
    //             list = calculateBufferDisplayDate(checkAndAddDates(item.staffAssignmentParts, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value), mainTimeStartTime.value)
    //             // 确定每一段分调令的长度，如果有displayDate那么则是按照display显示
    //             list = sortByDisplayDate(list)
    //             list = filterObjects(list, mainTimeStartTime.value, mainTimeEndTime.value, fcstDate.value)
    //           }
    //           return {
    //             startDate: item.on_board_date,
    //             endDate: item.actual_release_date,
    //             clientName: item.account, Enagement: item.engagementCode,
    //             EngagementDescription: item.engagementDescription,
    //             ProjectOnboardDate: item.on_board_date,
    //             hoursPerDay: item.hoursPerDay,
    //             staffAssignmentParts: list,
    //             type: '主',
    //             extensionAssumptionDate: (item.extensionAssumptionDate && moment(item.extensionAssumptionDate).isAfter(item.actual_release_date))?
    //                 item.extensionAssumptionDate : null,
    //             certainty: item.certainty,
    //           }
    //         }
    //     )
    //     modifyList = filterObjects(modifyList, mainTimeStartTime.value, mainTimeEndTime.value, fcstDate.value)
    //     // 检查和主调令的边界，并且计算内部的天数差据
    //     let checkList = checkAndAddDates(sortByDisplayDate(modifyList), mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
    //     // 计算和主调令的起始时间的偏移距离
    //     otherTimeList.value = calculateBufferDisplayDate(checkList, mainTimeStartTime.value)
    //   }
    //   // Task
    //   if (workTaskList.value && workTaskList.value.length) {
    //     // 从原数据中取出需要的数据
    //     let modifyList = workTaskList.value.map((item) => { return { startDate: item.beginDate, endDate: item.endDate, task: true, taskType: item.taskType, taskDetail: item.taskDetail, hours: item.hours } })
    //     modifyList = filterObjects(modifyList, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
    //     // 检查和主调令的边界，并且计算内部的天数差据
    //     modifyList = checkAndAddDates(modifyList, mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
    //     let checkList = checkAndAddDates(sortByDisplayDate(modifyList), mainTimeStartTime.value, mainTimeEndTime.value)
    //     // 计算和主调令的起始时间的偏移距离
    //     taskTimeList.value = calculateBufferDisplayDate(checkList, mainTimeStartTime.value)
    //   }
    //   actualTimeTaskList.value = sortByDisplayDate([...otherTimeList.value, ...taskTimeList.value]).reverse()
    //   //scrollToBottom('eightTimeLine')
    // })

    //需求匹配上项目假设时间
    let timeDemandList = ref([])
    watch(
        () => props.data.demandList,
        (val) => {
          if(val) {
            timeDemandList.value = []
            otherTimeList.value = []
            if(props.data.demandList && props.data.demandList.length>0) {
              let modifyList = props.data.demandList.map((item) => {
                let arveEndDate = ''
                if(item.useStaffLength && item.useStaffLength>=12) {
                  arveEndDate = moment(item.arveAssumptionStartDate).add(12,'months').format('YYYY-MM-DD')
                } else if(item.useStaffLength) {
                  let month = parseInt(item.useStaffLength)
                  arveEndDate = moment(item.arveAssumptionStartDate).add(month,'months').format('YYYY-MM-DD')
                } else {
                  arveEndDate = moment(item.arveAssumptionStartDate).add(6,'months').format('YYYY-MM-DD')
                }
                return {
                  startDate: item.arveAssumptionStartDate,
                  endDate: arveEndDate,
                  displayStartDate: '',
                  displayEndDate:'',
                  arveAssumptionProportion: item.arveAssumptionProportion,
                  arveAssumptionStartDate: item.arveAssumptionStartDate,
                  demandChannel: item.demandChannel,
                  demandName: item.demandName,
                  demandNumber: item.demandNumber,
                  useStaffLength: item.useStaffLength,
                }
              })
              //// modifyList = filterObjects(modifyList, mainTimeStartTime.value, mainTimeEndTime.value)
              // 检查和主调令的边界，并且计算内部的天数差据
              let checkList = checkAndAddDates(sortByDisplayDate(modifyList), mainTimeStartTime.value, mainTimeEndTime.value,fcstDate.value)
              // 计算和主调令的起始时间的偏移距离
              otherTimeList.value = calculateBufferDisplayDate(checkList, mainTimeStartTime.value)
              timeDemandList.value = sortByDisplayDate([...otherTimeList.value]).reverse()
            }
          }
          },
        {immediate:true}
    )
    // watchEffect(() => {
    //   timeDemandList.value = []
    //   otherTimeList.value = []
    //   if(demandList.value && demandList.value.length>0) {
    //     let modifyList = demandList.value.map((item) => {
    //       let arveEndDate = ''
    //       if(item.useStaffLength && item.useStaffLength>=12) {
    //         arveEndDate = moment(item.arveAssumptionStartDate).add(12,'months').format('YYYY-MM-DD')
    //       } else if(item.useStaffLength) {
    //         let month = parseInt(item.useStaffLength)
    //         arveEndDate = moment(item.arveAssumptionStartDate).add(month,'months').format('YYYY-MM-DD')
    //       } else {
    //         arveEndDate = moment(item.arveAssumptionStartDate).add(6,'months').format('YYYY-MM-DD')
    //       }
    //       return {
    //         startDate: item.arveAssumptionStartDate,
    //         endDate: arveEndDate,
    //         arveAssumptionProportion: item.arveAssumptionProportion,
    //         arveAssumptionStartDate: item.arveAssumptionStartDate,
    //         demandChannel: item.demandChannel,
    //         demandName: item.demandName,
    //         demandNumber: item.demandNumber,
    //         useStaffLength: item.useStaffLength,
    //       }
    //     })
    //     //modifyList = filterObjects(modifyList, mainTimeStartTime.value, mainTimeEndTime.value)
    //     // 检查和主调令的边界，并且计算内部的天数差据
    //     let checkList = checkAndAddDates(sortByDisplayDate(modifyList), mainTimeStartTime.value, mainTimeEndTime.value)
    //     // 计算和主调令的起始时间的偏移距离
    //     otherTimeList.value = calculateBufferDisplayDate(checkList, mainTimeStartTime.value)
    //     timeDemandList.value = sortByDisplayDate([...otherTimeList.value]).reverse()
    //   }
    // })


    const handleCancel = () => {
      emit("cancel-time")
    }

    return {
      handleCancel,
      dayDifference,
      dayStepLength,
      basicTimeLineWidth,
      mainTimeStartTime,
      mainTimeEndTime,
      actualTimeTaskList,
      exceedTimeList,
      timeDemandList,
      fcstExtensionAssumptionDateWidth,
      fcstDate,
    }
  }
})
</script>

<style scoped lang="less">
.eightTimeLine {
  width: 100%;
  height: 100%;
  //overflow: auto;
  margin: 10px 5vw;
  padding: 0 50px;

  .card-title {
    margin-left: -40px;
  }

  .basicTimeLine {
    height: 5px;
    background-color: rgb(49, 130, 206);
    margin: 0px auto 40px;
    position: absolute;

    .basicTimeArray {
      position: absolute;
      height: 40px;
      border: 1px dashed grey;
      border-bottom: 0px;
      border-top: 0px;
      top: 5px;

      .basicTimeStart {
        position: absolute;
        left: -35px;
        top: 50px;
      }

      .basicTimeEnd {
        position: absolute;
        right: -30px;
        top: 50px;
      }
    }
  }
  .fcstBasicTimeLine {
    height: 5px;
    border-top: 0.3vw dashed rgb(49, 130, 206);
    margin: 0px auto 40px;
    position: absolute;

    .basicTimeArray {
      position: absolute;
      height: 40px;
      border: 1px dashed grey;
      border-bottom: 0px;
      border-top: 0px;
      //top: 5px;

      .basicTimeStart {
        position: absolute;
        left: -35px;
        top: 50px;
      }

      .basicTimeEnd {
        position: absolute;
        right: -30px;
        top: 50px;
      }
    }
  }

  .explain {
    margin: 0px auto 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    padding: 5px 5px;

    .commonItem {
      margin-right: 60px;
    }

    .mainItem {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: relative;
        right: 0.52083vw;
        display: inline-block;
        background-color: #3182ce;
        width: 28px;
        height: 0.15vw;
      }
    }
    .current-mainItem {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: relative;
        right: 0.52083vw;
        display: inline-block;
        border-top: 1.5px dashed #3182ce;
        width: 28px;
      }
    }

    .assignItem {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: relative;
        right: 0.52083vw;
        display: inline-block;
        background-color: #77CAED;
        width: 28px;
        height: 0.15vw;
      }
    }

    .extension-assignItem {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: relative;
        right: 0.52083vw;
        display: inline-block;
        border-top: 1.5px dashed #77CAED;
        width: 28px;
      }
    }

    .otherItem {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: relative;
        right: 0.52083vw;
        display: inline-block;
        background-color: #BCB1FF;
        width: 28px;
        height: 0.15vw;
      }
    }

    .demand-otherItem {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: relative;
        right: 0.52083vw;
        display: inline-block;
        border-top: 1.5px dashed #F7BC6D;
        width: 28px;
      }
    }
  }

  .extraTimeLine {
    min-height: 180px;
    //margin: 0px auto;
    display: grid;
    grid-auto-rows: min-content;
    align-content: end;

    .specialTimeLine {
      height: 5px;
      //background-Color: rgb(119, 202, 237);
      margin-bottom: 100px;
      position: relative;

      .leftPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 60%;
        background-Color: rgb(188, 177, 255);
        left: -7px;
        top: -50%;
        cursor: pointer;
      }

      .rightPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-Color: rgb(119, 202, 237);
        right: -7px;
        top: -50%;
        cursor: pointer;
        z-index: 10
      }

      .fcstRightPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-Color: rgb(119, 202, 237);
        right: -7px;
        top: -20%;
        cursor: pointer;
        z-index: 10
      }

      .extraFcst {
        border-top: 0.3vw dashed rgb(119, 202, 237);
        top: -0.3vw;
        .extrafcstPopover {
          height: 1.7vw;
          border-right: 1px dashed grey;
        }
      }

      .extrabasic {
        background-Color: rgb(119, 202, 237);
        height: 0.3vw;
        .extraBasicPopover {
          height: 2.08333vw;
          border: 1px dashed grey;
          border-bottom: 0px;
          border-top: 0px;
        }
      }
    }


    .task {
      height: 5px;
      //background-Color: rgb(188, 177, 255);
      margin-bottom: 90px;
      position: relative;

      .leftPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 60%;
        background-Color: rgb(188, 177, 255);
        left: -7px;
        top: -50%;
        cursor: pointer;
      }

      .rightPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 60%;
        background-Color: rgb(188, 177, 255);
        right: -7px;
        top: -50%;
        cursor: pointer;
      }

      .extraBasicTask {
        background-Color: rgb(188, 177, 255);
        height: 0.3vw;
        .extraBasicPopover {
          height: 2.08333vw;
          border: 1px dashed grey;
          border-bottom: 0px;
          border-top: 0px;
        }
      }
    }

    .timeLeft {
      position: absolute;
      width: 280px;
      height: 10px;
      display: flex;
      right:20px;
      align-items: center;
      justify-content: flex-end;
    }

    .timeDisRight {
      position: absolute;
      width: 280px;
      height: 10px;
      left: 20px;
      display: flex;
      align-items: center
    }

    .specialDot {
      position: absolute;
      height: 20px;
      bottom: 6px;
      border: 1px dashed black;
      border-bottom: 0px;

      .picecLeft {
        background-color: blue !important
      }

      .pieceRight {
        background-color: blue !important
      }

      .pieceArray {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 20px;
        width: 100%;
        min-width: 90px;
        font-weight: 700;

        .pieceSpecial {
          position: relative;
          left: -45px;
          bottom: 3px
        }
      }
    }

    .timeBottom {
      position: absolute;
      width: 280px;
      height: 10px;
      display: flex;
      right:-1.5vw;
      align-items: center;
      justify-content: flex-end;
      top: 2.5vw;
    }

    .timeDisBottom {
      position: absolute;
      width: 280px;
      height: 10px;
      left: -1.7vw;
      display: flex;
      align-items: center;
      top: 2.5vw;
    }
  }

  .exceedRed {
    height: 3px;
    background-Color: red;
    position: absolute;
    bottom: 2px;
    cursor: pointer;

    .specialExceed {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      left: -50%;
      margin-left: -6px;
      background-Color: red;
      z-index: 99
    }

    .exceedSplit {
      margin-top: 8px;
      min-width: 90px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: red;
      font-size:0.5rem
    }
  }

  .specialTimeLine:nth-last-child(1) {
    margin-bottom: 90px !important
  }

  :deep(.specialTimeLinePopover) {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leftArea {
        width: 210px;
        height: 30px;
      }

      .rightArea {
        width: 180px;
        height: 30px;
        text-align: right
      }
    }

    .largeContainer {
      padding: 0px 0px 20px;

      .leftArea {
        width: 210px !important;
        height: 40px !important;
      }

      .rightArea {
        width: 190px !important;
        height: 40px !important;
        text-align: right
      }
    }
  }

  .demandTimeLine {
    min-height: 180px;
    //margin: 0px auto;
    display: grid;
    grid-auto-rows: min-content;
    align-content: end;

    .demandTime {
      //height: 5px;
      //background-Color: #F7BC6D;
      margin-bottom: 100px;
      position: relative;
      border-top: 0.3vw dashed #F7BC6D;

      .leftPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 60%;
        background-Color: #F7BC6D;
        left: -7px;
        top: -15%;
        cursor: pointer;
      }

      .rightPoint {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-Color: #F7BC6D;
        right: -7px;
        top: -15%;
        cursor: pointer;
        z-index: 10
      }

      .timeBottom {
        position: absolute;
        width: 280px;
        height: 10px;
        display: flex;
        right:-1.5vw;
        align-items: center;
        justify-content: flex-end;
        top: 2.5vw;
      }

      .timeDisBottom {
        position: absolute;
        width: 280px;
        height: 10px;
        left: -1.7vw;
        display: flex;
        align-items: center;
        top: 2.5vw;
      }

      .timeLeft {
        position: absolute;
        width: 280px;
        height: 10px;
        display: flex;
        right:20px;
        align-items: center;
        justify-content: flex-end;
      }

      .demandPopover{
        height: 2.08333vw;
        border: 1px dashed grey;
        border-bottom: 0px;
        border-top: 0px;
      }
    }

  }

}
</style>
